import React from 'react'
import { Formik } from "formik";

// import Login from './Login'
import LoginForm from './LoginForm'
import SigninForm from './SiginForm'

const AccountSignIn = () => {
  return (
    <div className="">
      <div className="container-title-header">
        <h2 className="account-signin-title mb-0 text-center">Account Sign In</h2>
      </div>
      <div className="container sigin-container ">
        <div className="row py-3 row-cols-1 row-cols-lg-2">
          <div className="feature col p-5"  >            
            <LoginForm />
          </div>
          <div className="feature col p-5"  >
            <SigninForm/>            
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSignIn