import React, { useEffect, useState, useContext } from 'react';
import DataContext from '../../context/DataContext';

const Voucher = () => {

    const [icon, setIcon] = useState("");
    const [message, setMessage] = useState("");
    const [orderNumber, setOrderNumber] = useState("");
    const [orderConfirmed, setOrderConfirmed] = useState([]);
    const [totalCart, setTotalCart] = useState(0)

    const { editProduct, setEditProduct } = useContext(DataContext);
    const { productSelected, setProductSelected } = useContext(DataContext);
    const { order, setOrder } = useContext(DataContext);

    useEffect(() => {

        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("orderId")) {
            let order = JSON.parse(localStorage.getItem('order'));
            setOrderNumber(query.get("orderId"))
            setOrderConfirmed(order)

            let total = 0;
            if (order) {
                order.forEach(product => {
                    product.items.forEach(step => {
                        total += Number(step.total);
                        step.items.forEach(item => {
                            total += Number(item.total);
                        });
                    });
                });
            }

            setTotalCart(total.toFixed(2));

            console.log("🚀 ~ file: Voucher.js ~ line 23 ~ useEffect ~ JSON.parse(localStorage.getItem('order'))", JSON.parse(localStorage.getItem('order')))
        }

        if (query.get("success")) {
            setIcon("/statics/check.png")
            setMessage("Order placed! You will receive an email confirmation.");

            setEditProduct(false);
            localStorage.removeItem('localProductSelected');
            setProductSelected({});            
            localStorage.removeItem('order');
            setOrder([]);   
        }

        if (query.get("canceled")) {
            setIcon("/statics/canceled.png")
            setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
        }


    }, []);

    return (
        <div className="">
            <div className="container-title-header">
                <h2 className="item-title mb-0 text-center">Order Confirmation</h2>
            </div>

            <div className="container voucher-container">
                <div className="row p-1 row-cols-1 row-cols-lg-1">
                    <div className="feature col p-5"  >
                        <p className='text-center'>
                            <img src={icon} alt="" />
                        </p>
                        <p className='text-center' >{message}</p>
                        {(orderNumber) && <h3 className='text-center'>Order Number {orderNumber}</h3>}
                        {orderConfirmed.map(product => (
                            <ul className="list-group mb-3">
                                <li className="list-group-item d-flex justify-content-between lh-sm">
                                    <div>
                                        <h6 className="my-0">Product</h6>
                                    </div>
                                    <div>
                                        <span className="text-muted">{product.product_name}</span>
                                    </div>
                                </li>
                                {product.items.map(item => (
                                    <li className="list-group-item d-flex justify-content-between lh-sm">
                                        <div>
                                            <h6 className="my-0">{item.step_label}</h6>
                                        </div>
                                        <div>
                                            {item.items.map(child => (
                                                <span className="text-muted">{child.item_label} {child.value}</span>
                                            ))}
                                        </div>
                                    </li>
                                ))}
                            </ul>

                        ))}

                        <ul className="list-group m-3">
                            <li className="d-flex justify-content-between">
                                <div>
                                    <h6 className="my-0">
                                        Product
                                    </h6>
                                </div>
                                <span className="text-muted">
                                    ${totalCart}
                                </span>
                            </li>
                            <li className="d-flex justify-content-between">
                                <div>
                                    <h6 className="my-0">
                                        Shipping
                                    </h6>
                                </div>
                                <span className="text-muted">
                                    Free
                                </span>
                            </li>
                            <li className=" d-flex justify-content-between">
                                <span>Total</span>
                                <strong>${totalCart}</strong>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Voucher