import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, useFormik } from "formik";
import * as Yup from "yup";

import useAuth from '../../hooks/useAuth';
// import DataContext from '../../context/DataContext';
import authService from '../../services/auth.service';

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required("Required"),
    lastName: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email()
        .required("Required"),
    password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number."),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    companyName: Yup.string()
        .required("Required"),
    companyPhone: Yup.string()
        .required("Required"),
    companyAddress: Yup.string()
        .required("Required"),
    companyCountry: Yup.string()
        .required("Required"),
    companyState: Yup.string()
        .required("Required"),
    companyCity: Yup.string()
        .required("Required"),
    companyZipCode: Yup.string()
        .required("Required"),
});


const SiginForm = () => {

    const { setAuth } = useAuth();
    const [message, setMessage] = useState(null);

    // const { setSession } = useContext(DataContext);

    const navigate = useNavigate();

    const onSubmit = async (values) => {
        console.log("🚀 ~ file: SiginForm.js ~ line 29 ~ onSubmit ~ values", values)
        setMessage(null);

        authService.register(values).then((response) => {
            console.log("🚀 ~ file: SiginForm.js ~ line 33 ~ authService.register ~ response", response)

            setMessage({ error: false, msg: "Register successfully ... redirecting ... " });

            const fullName = response?.fullName;
            const discount = response?.discount
            const accessToken = response?.accessToken;
            const roles = response?.roles;
            const state = response?.state;

            console.log("🚀 ~ file: LoginForm.js ~ line 42 ~ authService.login ~ response", response)

            setAuth({ ...values, fullName, discount, state, roles, accessToken })

            //tmp
            localStorage.setItem("persist", JSON.stringify({ email: values.email, fullName, discount, state, roles, accessToken }));


            let timer = setTimeout(() => {
                setMessage(null);
                clearTimeout(timer);
                timer = null;

                navigate('/shades');
            }, 2000);

        }).catch((err) => {
            console.log("🚀 ~ file: SiginForm.js ~ line 37 ~ authService.register ~ err", err)

            setMessage({ error: true, msg: err.response.data.response });
            let timer = setTimeout(() => {
                setMessage(null);
                clearTimeout(timer);
                timer = null;
            }, 5000);
        });
    };

    const formik = useFormik({
        initialValues: { firstName: "", lastName: "", email: "", password: "", passwordConfirmation: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <>
            <h6 className="account-tittle pb-4 border-bottom">CREATE NEW ACCOUNT</h6>
            <form onSubmit={formik.handleSubmit} className="mt-5">
                {message ?
                    <div class={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                        {message.msg}
                    </div>
                    : null
                }
                <div className="mb-3">
                    <input
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.firstName && formik.touched.firstName && "error"}`}
                    />
                    {formik.errors.firstName && formik.touched.firstName && (
                        <div className="input-feedback">{formik.errors.firstName}</div>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.lastName && formik.touched.lastName && "error"}`}
                    />
                    {formik.errors.lastName && formik.touched.lastName && (
                        <div className="input-feedback">{formik.errors.lastName}</div>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        name="email"
                        type="text"
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.email && formik.touched.email && "error"}`}
                    />
                    {formik.errors.email && formik.touched.email && (
                        <div className="input-feedback">{formik.errors.email}</div>
                    )}
                </div>
                <div className="mb-3">
                    <p>
                        <small>Password must be 8 or more characters long.
                            Avoid words, numbers and phrases associated with you.</small>
                    </p>
                    <input
                        name="password"
                        type="password"
                        placeholder="Enter your password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.password && formik.touched.password && "error"}`}
                    />
                    {formik.errors.password && formik.touched.password && (
                        <div className="input-feedback">{formik.errors.password}</div>
                    )}
                </div>
                <div className="mb-3">

                    <input
                        name="passwordConfirmation"
                        type="password"
                        placeholder="Confirm password"
                        value={formik.values.passwordConfirmation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && "error"}`}
                    />
                    {formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && (
                        <div className="input-feedback">{formik.errors.passwordConfirmation}</div>
                    )}
                </div>

                <div className="mb-3">
                    <p>
                        <small>Please share your Name , company informantion and the best way to reach you in case we need to contact you.</small>
                    </p>
                    <input
                        name="companyName"
                        type="text"
                        placeholder="Company Name"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.companyName && formik.touched.companyName && "error"}`}
                    />
                    {formik.errors.companyName && formik.touched.companyName && (
                        <div className="input-feedback">{formik.errors.companyName}</div>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        name="companyPhone"
                        type="text"
                        placeholder="Company Phone"
                        value={formik.values.companyPhone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.companyPhone && formik.touched.companyPhone && "error"}`}
                    />
                    {formik.errors.companyPhone && formik.touched.companyPhone && (
                        <div className="input-feedback">{formik.errors.companyPhone}</div>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        name="companyAddress"
                        type="text"
                        placeholder="Company Address"
                        value={formik.values.companyAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.companyAddress && formik.touched.companyAddress && "error"}`}
                    />
                    {formik.errors.companyAddress && formik.touched.companyAddress && (
                        <div className="input-feedback">{formik.errors.companyAddress}</div>
                    )}
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <input
                            name="companyCountry"
                            type="text"
                            placeholder="Country"
                            value={formik.values.companyCountry}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.errors.companyCountry && formik.touched.companyCountry && "error"}`}
                        />
                        {formik.errors.companyCountry && formik.touched.companyCountry && (
                            <div className="input-feedback">{formik.errors.companyCountry}</div>
                        )}
                    </div>
                    <div className="col">
                        <input
                            name="companyState"
                            type="text"
                            placeholder="State"
                            value={formik.values.companyState}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.errors.companyState && formik.touched.companyState && "error"}`}
                        />
                        {formik.errors.companyState && formik.touched.companyState && (
                            <div className="input-feedback">{formik.errors.companyState}</div>
                        )}
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <input
                            name="companyCity"
                            type="text"
                            placeholder="City"
                            value={formik.values.companyCity}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.errors.companyCity && formik.touched.companyCity && "error"}`}
                        />
                        {formik.errors.companyCity && formik.touched.companyCity && (
                            <div className="input-feedback">{formik.errors.companyState}</div>
                        )}
                    </div>
                    <div className="col">
                        <input
                            name="companyZipCode"
                            type="text"
                            placeholder="Zip Code"
                            value={formik.values.companyZipCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.errors.companyZipCode && formik.touched.companyZipCode && "error"}`}
                        />
                        {formik.errors.companyZipCode && formik.touched.companyZipCode && (
                            <div className="input-feedback">{formik.errors.companyState}</div>
                        )}
                    </div>
                </div>

                <div className="my-2 d-flex justify-content-end">
                    <button type="submit" disabled={!formik.isValid} className="btn btn-abrusci-primary  btn-sm col-md-4">Register </button>
                </div>
            </form>
        </>
    )
}

export default SiginForm