import React from 'react';


const Navbar = () => {
  return (

    <nav className='bg-nav'>
      <div className="container d-flex bd-highlight">
        <div className="me-auto p-2 bd-highlight">
          <a href="tel:+13054063401">
            <i class="fa-solid fa-phone"></i>
            <small> +1 305 406 3401 </small>
          </a>
        </div>
        <div className="p-2 bd-highlight">
          <a href="https://www.instagram.com/decoabrusci/" target='_blank' rel="noopener noreferrer">
            <i class="fa-brands fa-instagram"></i>
          </a>
        </div>
        <div className="p-2 bd-highlight">
          <a href="https://www.facebook.com/decoabrusci" target='_blank' rel="noopener noreferrer">
            <i class="fa-brands fa-facebook"></i>
          </a>
        </div>
        <div className="p-2 bd-highlight">
          <a href="https://twitter.com/decoabrusci" target='_blank' rel="noopener noreferrer">
            <i class="fa-brands fa-twitter"></i>
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar