import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2'
import { v4 as uuidv4 } from 'uuid';
import DataContext from '../../context/DataContext';
import useAuth from '../../hooks/useAuth';
import { currencyFormatted } from '../../helpers';

const Cart = () => {

  const [totalProducts, setTotalProducts] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [totalCart, setTotalCart] = useState(0)

  const { editProduct, setEditProduct } = useContext(DataContext);
  const { order, setOrder } = useContext(DataContext);
  // const { session } = useContext(DataContext);
  const { auth } = useAuth();





  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setDiscount(0)
    if (auth) {
      setDiscount(Number(auth.discount) ? Number(auth.discount) : 0)
    }
  }, [auth])

  useEffect(() => {
    console.log("🚀 ~ file: Cart.js ~ line 29 ~ useEffect ~ discount", discount)

    let total = 0;
    let discountValue = discount / 100;

    if (order) {
      order.forEach(product => {
        product.items.forEach(step => {
          total += Number(step.total);
          step.items.forEach(item => {
            total += Number(item.total);
          });
        });
      });
    }

    total += (total * 0.6);

    setTotalProducts(total.toFixed(2))

    let totalDiscount = total * (discount / 100);

    setTotalDiscount(totalDiscount.toFixed(2));

    total = total - totalDiscount;

    setTotalCart(total.toFixed(2));

  }, [order, discount]);


  const onDeleteProduct = (product) => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#b49f96',
      cancelButtonColor: '#9599a4',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        if (deleteProduct(product)) {
          Swal.fire({
            title: 'Deleted!',
            text: 'Your product has been deleted.',
            icon: 'success',
            confirmButtonColor: '#b49f96'
          })
        }
      }
    });
  }

  const deleteProduct = (product) => {

    const localOrder = JSON.parse(localStorage.getItem('order'));

    const indexToRemove = localOrder.findIndex(element => element.product_id === product.product_id);

    if (indexToRemove > -1) {
      localOrder.splice(indexToRemove, 1);
      setOrder([...localOrder]);
      return true;
    }
    return false;
  }

  const onEditProduct = (product) => {
    console.log("🚀 ~ file: Cart.js ~ line 15 ~ editProduct ~ product", product)

    Swal.fire({
      title: 'Do you want to edit this product?',
      text: "You won't be able to revert this!",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#b49f96',
      cancelButtonColor: '#9599a4',
      confirmButtonText: 'Yes, edit it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setEditProduct(true);
        navigate(`/items/${product.product_id}/edit/${product.uid}`)
      }
    });
  }

  const onCopyProduct = async (product) => {
    console.log("🚀 ~ file: Cart.js ~ line 20 ~ copyProduct ~ product", product)
    const { value: roomName } = await Swal.fire({
      title: 'Enter your new room name',
      input: 'text',
      inputLabel: 'Your room name',
      showCancelButton: true,
      confirmButtonColor: '#b49f96',
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!'
        }
      }
    })

    if (roomName) {
      if (copyProduct(product, roomName)) {
        Swal.fire({
          title: 'Copied!',
          text: 'Your product has been copied.',
          icon: 'success',
          confirmButtonColor: '#b49f96'
        })
      }
    }
  }

  const copyProduct = (product, roomName) => {

    const localOrder = JSON.parse(localStorage.getItem('order'));

    const indexToCopy = localOrder.findIndex(element => element.product_id === product.product_id);

    if (indexToCopy > -1) {
      const newOrder = [...localOrder];
      const newProduct = localOrder[indexToCopy];

      newOrder.push({ ...newProduct, uid: uuidv4() });

      setOrder(newOrder);

      return true;
    }
    return false;
  }

  const getRoomName = (product) => {

    let step = product.items[product.items.length - 1];

    let label = [];
    step.items.forEach(item => {
      label.push(item.value);
    })

    return label.join(' - ');
  }


  return (
    <div className="">
      <div className="container-title-header">
        <h2 className="item-title mb-0 text-center">Shopping Cart</h2>
      </div>

      {order.length > 0
        ?
        <>
          {order.map(product => (
            <div className='container order-container'>

              <div className="row">
                <div className="d-flex bd-highlight bg-light px-0">
                  <div className="cart-header-table p-2 flex-grow-1 bd-highlight">{getRoomName(product)}</div>
                  <div className="cart-header-table p-2 bd-highlight">
                    <button class="btn" onClick={() => onDeleteProduct(product)} ><i class="fa-solid fa-trash-can"></i></button>
                    <button class="btn" onClick={() => onCopyProduct(product)}><i class="fa-solid fa-clone"></i></button>
                    <button class="btn" onClick={() => onEditProduct(product)}><i class="fa-solid fa-file-pen"></i></button>
                  </div>
                </div>
              </div>


              <div className="row py-3 row-cols-1 row-cols-lg-2">
                <div className="feature col"  >
                  <p>
                    <img className='image-shade' src={product.product_image} alt="" />
                  </p>
                </div>
                <div className="feature col"  >
                  <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between lh-sm">
                      <div>
                        <h6 className="my-0">Product</h6>
                      </div>
                      <div>
                        <span className="text-muted">{product.product_name}</span>
                      </div>
                    </li>
                    {product.items.map(item => (
                      <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                          <h6 className="my-0">{item.step_label}</h6>
                        </div>
                        <div>
                          {item.items.map(child => (
                            <span className="text-muted"> {''} {child.item_label} {''} {child.item_label_value}</span>
                          ))}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}

          <div className="container order-container pb-4">
            <div className="row p-1 row-cols-1 row-cols-lg-2 order-resume-container mx-1">
              <div className="feature col border-end p-5 mt-4 mb-4"  >
                <p>
                  The Shade Store has partnered with the Arbor Day Foundation to plant a
                  tree in one of our national forests in the name of every person who places
                  an order with us. We are fully committed to using eco-friendly materials
                  from sustainable resources – and thanks to our customers – the world is a
                  little greener.
                </p>
              </div>
              <div className="feature col p-5">
                <div className="row g-2 px-5">
                  <ul className="list-group mb-3">
                    <li className="d-flex justify-content-between">
                      <div>
                        <h6 className="my-0">
                          Product
                        </h6>
                      </div>
                      <span className="text-muted">
                        {currencyFormatted(totalProducts)}
                      </span>
                    </li>
                    {auth && (
                      <li className="d-flex justify-content-between">
                        <div>
                          <h6 className="my-0">
                            Discount <span>{discount}%</span>
                          </h6>
                        </div>
                        <span className="text-muted">
                          {currencyFormatted(totalDiscount)}
                        </span>
                      </li>
                    )}
                    <li className="d-flex justify-content-between">
                      <div>
                        <h6 className="my-0">
                          Shipping
                        </h6>
                      </div>
                      <span className="fw-bold">
                        ASK SALES DEPARTAMENT FOR DETAILS
                      </span>
                    </li>
                    <li className=" d-flex justify-content-between">
                      <span>Total</span>
                      <strong>{currencyFormatted(totalCart)}</strong>
                    </li>
                  </ul>
                  <ul>
                    <li className=" d-flex justify-content-between">
                      <span class="fs-5 fw-bolder" >NOTE: PLEASE FOR SHIPPING ASK SALES DEPARTAMENT FOR DETAILS</span>
                    </li>
                  </ul>
                </div>



                <div className="row g-2 px-5 py-5 row-cols-1 row-cols-lg-1">

                  {auth ? (
                    <form action="https://webhook.decoabrusci.us/create-checkout-session" method="POST" className='row-cols-1 row-cols-lg-1'>
                      {/* <form action="http://localhost:3002/create-checkout-session" method="POST" className='row-cols-1 row-cols-lg-1'> */}
                      <input type="hidden" name="user" value={JSON.stringify(auth)} />
                      <input type="hidden" name="order" value={JSON.stringify(order)} />
                      <button type="submit" className="btn btn-buy-now" >
                        Checkout
                      </button>
                    </form>

                  ) : (

                    <Link to={'/signin'} replace state={{ from: location }} className='btn btn-buy-now'>
                      Login
                    </Link>
                  )}
                  <Link to={'/products'} className='btn btn-continue-shopping'>
                    Continue Shopping
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </>
        :
        <div class="py-5 text-center">
          {/* <h2>Abrusci</h2> */}
          <p class="lead">There aren't products in shopping cart</p>
          <Link to={'/products'} className='btn btn-continue-shopping'>
            Continue buying
          </Link>
        </div>
      }
    </div>
  )
}

export default Cart