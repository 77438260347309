import { v4 as uuidv4 } from 'uuid';
import Radio from '../control/Radio';
import Text from '../control/Text';
import Select from '../control/Select';

const Custom = ({ steps, setSteps, step, children }) => {

    const switchControls = (item) => {
        switch (item.item_type) {
          case 'text':
            return <Text children={[item]} key={uuidv4()}/>;
          case 'radio':
            return <Radio steps={steps} setSteps={setSteps} step={step} children={[item]} key={uuidv4()}/>;
          case 'select':
            return <Select steps={steps} setSteps={setSteps} step={step} children={[item]} key={uuidv4()} />;          
          default:
            break;
        }
      }

    return (
        <>
            {children.map(child => (
                 switchControls(child)
            ))}
        </>
    )
}

export default Custom;