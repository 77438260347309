import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'
import ShadeService from '../../services/shades.service';
import DataContext from '../../context/DataContext';

import Custom from '../control/Custom';
import Radio from '../control/Radio';
import Text from '../control/Text';
import Select from '../control/Select';
import Image from '../control/Image';


const Items = () => {

  const { product_id, uid } = useParams();
  const [product, setProduct] = useState({ name: 'Window' });
  const [steps, setSteps] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [productItemsSelected, setProductItemsSelected] = useState({ current_step: "" });
  const [accordion, setAccordion] = useState({ step_id: 0 });
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 1024;
  const { productSelected, setProductSelected } = useContext(DataContext);
  const { editProduct, setEditProduct } = useContext(DataContext);



  useEffect(() => {
    getDataSteps(product_id);
  }, []);


  useEffect(() => {
    setProductItemsSelected(productSelected)
  }, [productSelected]);


  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {



    if (dataFetched) {

      const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

      if (uid) {

        if (localProductSelected === null) {
          loadProductFromOrder();
        } else {
          if (Object.keys(localProductSelected).length > 0 && localProductSelected.hasOwnProperty('uid')) {
            if (localProductSelected.uid != uid) {
              loadProductFromOrder();
            } else {
              setEditProduct(true);
              setAccordion({ step_id: localProductSelected.current_step });
              setProductSelected(localProductSelected);
            }
          }
        }
      } else {
        if (localProductSelected === null) {
          createProductDefault();
        } else {
          if (Object.keys(localProductSelected).length > 0 && localProductSelected.hasOwnProperty('product_id')) {
            if (localProductSelected.product_id != product_id) {
              createProductDefault();
            } else {
              setAccordion({ step_id: localProductSelected.current_step })
            }
          }
        }
      }
    }
  }, [steps]);

  const createProductDefault = () => {

    let current_step = 0;
    let stepsListSelected = [];
    steps.forEach((step, idx) => {

      if (idx === 0) {
        current_step = step.id
        setAccordion({ step_id: current_step })
      }

      let stepSelected = {
        step_id: step.id,
        step_name: step.step_title,
        step_label: step.step_label,
        get_data: step.step_get_data,
        get_data_url: step.step_get_data_url,
        execute_function: step.step_execute_function ? step.step_execute_function.split(",") : [],
        enable_searcher : step.step_enable_searcher,
        items: [],
        total: 0
      };

      step.items.forEach(item => {
        item.children.forEach((child) => {
          if (child.item_default === 1) {

            let aux_item = {
              step_id: child.step_id,
              step_name: step.step_title,
              item_label: (item.item_label) ? item.item_label : child.item_label,
              item_id: child.id,
              item_label_formula: (child.item_label_formula) ? child.item_label_formula : item.item_label_formula,
              item_label_value: child.item_name,
              value: child.item_name,
              control: child.item_type,
              is_required: child.item_is_required,
              change_image: child.item_image_willUpdateLayout,
              image: child.item_image,
              total: 0
            }

            if (aux_item.item_label_formula === 'multichannel') {
              aux_item.disabled = true;
            }

            if (aux_item.item_label_formula === 'solar_panel') {
              aux_item.disabled = true;
            }

            stepSelected.items.push(aux_item);
          }
        });
      });
      stepsListSelected.push(stepSelected);
    });

    console.log("🚀 ~ file: Items.js ~ line 141 ~ createProductDefault ~ stepsListSelected", stepsListSelected)
    // setProductItemsSelected({ uid: uuidv4(), product_id: product_id, product_name: product.name, product_image: product.imageURL, current_step, items: stepsListSelected })
    setProductSelected({ uid: uuidv4(), product_id: product_id, product_name: product.name, product_image: product.imageURL, product_base_image: product.baseImageURL, current_step, items: stepsListSelected })
    localStorage.setItem('localProductSelected', JSON.stringify({ uid: uuidv4(), product_id: product_id, product_name: product.name, product_image: product.imageURL, product_base_image: product.baseImageURL, current_step, items: stepsListSelected }));
  }

  const loadProductFromOrder = () => {
    console.log("🚀 ~ file: Items.js ~ line 73 ~ loadProductFromOrder ~ uid", uid)

    const localOrder = JSON.parse(localStorage.getItem('order'));
    const indexToLoad = localOrder.findIndex(element => element.uid === uid);
    if (indexToLoad > -1) {
      const productToEdit = localOrder[indexToLoad];
      console.log("🚀 ~ file: Items.js ~ line 145 ~ loadProductFromOrder ~ productToEdit", productToEdit)
      setAccordion({ step_id: productToEdit.current_step })
      setProductItemsSelected({ ...productToEdit })
      localStorage.setItem('localProductSelected', JSON.stringify({ ...productToEdit }));
    }
  }

  const renderItems = (step, items) => {

    return (
      items.map(item => (
        <>
          <div className={`col-md-${item.item_col_size}`} key={uuidv4()}>
            <div className={`row px-2 ${step.step_enable_searcher == 1 && 'container-step'}`}>
              <p className="fs-6 fw-light text-uppercase text-center" key={uuidv4()}> {item.item_name} </p>
              {switchControls(step, item)}
            </div>
          </div>
        </>
      ))
    )
  }

  const switchControls = (step, item) => {
    switch (item.item_type) {
      case 'text':
        return <Text key={uuidv4()} children={item.children} />;
      case 'select':
        return <Select key={uuidv4()} steps={steps} setSteps={setSteps} step={step} children={item.children} />;
      case 'radio':
        return <Radio key={uuidv4()} steps={steps} setSteps={setSteps} step={step} children={item.children} />;
      case 'custom':
        return <Custom key={uuidv4()} steps={steps} setSteps={setSteps} step={step} children={item.children} />;
      default:
        break;
    }
  }

  // API 
  const getDataSteps = (product_id) => {

    ShadeService.getAllDataSteps(product_id).then(response => {
      if (response.status == 200) {
        setProduct(response.data.product);
        setSteps(response.data.steps);
        setDataFetched(true)
      }
    }).catch(e => {
      console.log(e);
    });
  }

  const getItemSelected = (step) => {

    let selection = "";

    if (productItemsSelected !== null && productItemsSelected.hasOwnProperty('items')) {

      let res = productItemsSelected.items.find(e => e.step_id === step.id);

      if (res) {
        console.log("🚀 ~ file: Accordion.js ~ line 40 ~ getItemSelected ~ res", res)
        // selection = res.step_label;

        res.items.forEach(item => {
          console.log("🚀 ~ file: Items.js ~ line 207 ~ getItemSelected ~ item", item.item_label, item.value)
          // selection += (item.item_label) ? item.item_label + '' + item.value : '' + " " + item.value
          selection += " "
          selection += (item.item_label) ? item.item_label + '' + item.item_label_value : ' ' + " " + item.item_label_value
        });

        console.log("🚀 ~ file: Accordion.js ~ line 40 ~ getItemSelected ~ res", selection)

      }
    }

    return selection;
  }

  const onNextStep = (step) => {
    console.log("🚀 ~ file: Items.js ~ line 225 ~ onNextStep ~ step", steps, step, accordion)

    let index = steps.findIndex(el => el.id === step.id);

    if (onValidStep(step)) {
      let nextStep = steps[(index + 1)];
      setAccordion({ step_id: nextStep.id, collapsed: false, expanded: false, });
    } else {

    }
  }

  const onBackStep = (step) => {
    console.log("🚀 ~ file: Items.js ~ line 225 ~ onNextStep ~ step", step, accordion)

    let index = steps.findIndex(el => el.id === step.id);

    let backStep = steps[(index - 1)];

    setAccordion({ step_id: backStep.id, collapsed: false, expanded: false, })
  }

  const onValidStep = (step) => {
    console.log("🚀 ~ file: Items.js ~ line 239 ~ onValidStep ~ step", step)

    let isValid = true;

    if (productItemsSelected !== null && productItemsSelected.hasOwnProperty('items')) {

      let res = productItemsSelected.items.find(e => e.step_id === step.id);

      if (res) {

        res.items.forEach(item => {
          if (item.is_required === 1) {
            if (item.value === null || item.value === "") {
              isValid = false              
            }
          }
        });

        if (!isValid) {
          Swal.fire({
            title: 'All fields needs to be filled',
            text: 'You need to  choose an option',
            icon: 'error',
            confirmButtonColor: '#b49f96'
          })
        }


        return isValid;
      }
    }

    return false;
  }

  function truncateString(str, num) {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  return (
    <div className="">
      <div className="container-title-header">
        <h2 className="item-title mb-0 text-center">{product.name}</h2>
      </div>
      <div className="py-2" style={{
        backgroundImage: `url(${product.backgroundImageURL})`
      }}  >
        <div className="container">

        <div className={`row py-5 row-cols-1 ${!isMobile && 'row-cols-lg-2'}`}>
            {isMobile ? null :
              <div className="feature col tail" >
                <Image />
              </div>
            }

            <div className="feature col">
              <div className="accordion container-steps" id="accordionSteps">

                {steps.map((step, index) => (

                  <div className="accordion-item" key={uuidv4()}>
                    <h2 className="accordion-header" id={`step_${step.id}`}>
                      {/* <div className={step.id === productItemsSelected.current_step ? "px-0 py-0 accordion-button" : "px-0 py-0 accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${step.id}`} aria-expanded={step.id === productItemsSelected.current_step ? "true" : "false"} aria-controls={`collapse${step.id}`}> */}
                      <div className={`px-0 py-0 accordion-button ${step.id === accordion.step_id ? '' : 'collapsed'}`} type="button" data-bs-toggle="" data-bs-target={`#collapse${step.id === accordion.step_id ? step.id : ''}`} aria-expanded={step.id === accordion.step_id ? "true" : 'false'} aria-controls={`collapse${step.id}`}>

                        <div className="container px-0">
                          <div className="row mx-0">
                            <div className='col-md-3 step-number-container px-2 py-2'>
                              <h6>Step {step.step_number}</h6>
                            </div>
                            <div className='col-md-6 step-title-container px-2 py-2'>
                              <h6 className='text-truncate' style={{ maxWidth: (isMobile) ? 230 + 'px' : 100 + '%' }}>{step.step_title} :</h6>
                            </div>
                            <div className='col-md-4 step-selected-container px-2 py-2'>
                              <h6 className='' style={{ fontSize: 11 + 'px', maxWidth: (isMobile) ? 230 + 'px' : 100 + '%' }}>{getItemSelected(step)}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </h2>
                    <div key={uuidv4()} id={`collapse${step.id}`} className={`accordion-collapse collapse ${step.id === accordion.step_id ? `show` : ``}`} aria-labelledby={`step_${step.id}`} data-bs-parent="#accordionSteps">
                      <div className="accordion-body p-0">

                        <div className="container py-3">

                          <div className="row" >
                            {(step.step_subtitle)
                              ?
                              <div className="item-description-container">
                                <p className="fs-6 fw-light text-uppercase"> {step.step_subtitle} </p>
                              </div>
                              :
                              null
                            }
                          </div>

                          <div className="row" >
                            {
                              dataFetched ? renderItems(step, step.items) : null
                            }
                          </div>

                          <div className="d-flex justify-content-between bd-highlight mt-2">
                            <div className="mt-2 bd-highlight">
                              {(index > 0) ?
                                <button type="button" className="btn btn-back-step" onClick={() => onBackStep(step)}>
                                  <i className="fa-solid fa-angles-left"></i> Back
                                </button>
                                : ''}
                            </div>
                            <div className="mt-2 bd-highlight">
                              {(index + 1 < steps.length) ?
                                <button type="button" className="btn btn-next-step" onClick={() => onNextStep(step)}>
                                  Next <i className="fa-solid fa-angles-right"></i>
                                </button>
                                : ''}
                            </div>
                          </div>
                        </div>

                        {(step.step_note && step.step_note.length > 0) && (
                          <div className="row mt-2 p-5 container-help-text">
                            <p>
                              <div dangerouslySetInnerHTML={{ __html: step.step_note }} />
                            </p>
                          </div>

                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Items;