import React, { createContext, useState, useEffect } from 'react';

//https://github.com/gitdagray/react_context
//https://gist.github.com/jimode/c1d2d4c1ab33ba1b7be8be8c50d64555

const DataContext = createContext({});

export const DataProvider = ({ children }) => {

    const [session, setSession] = useState(null);    
    const [editProduct, setEditProduct] = useState(false);
    const [productSelected, setProductSelected] = useState({});
    const [order, setOrder] = useState([]);
    const [totalProduct, setTotalProduct] = useState(0);

    // Test 
    const [controlDisabled, setControlDisabled] = useState([{ item_id: 0, disabled: false }]);

     // Session - Local Storage: setting & getting data
     useEffect(() => {
        const session = JSON.parse(localStorage.getItem('user'));

        if (session) {
            setSession(session);
        } else {
            setSession(null);
        }
    }, [])

    useEffect(() => {
        console.log("🚀 ~ file: DataContext.js ~ line 31 ~ useEffect ~ session", session)

        localStorage.setItem('user', JSON.stringify(session));
    }, [session]);

    
    // Calculate Total Order
    useEffect(() => {

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected && Object.keys(localProductSelected).length > 0) {
            let total = 0;

            localProductSelected.items.forEach(step => {
                total += Number(step.total);
                step.items.forEach(item => {
                    total += Number(item.total);
                });
            });

            total += (total * 0.6);
            
            setTotalProduct(total.toFixed(2));
        }

    }, [productSelected]);


    // Order - Local Storage: setting & getting data
    useEffect(() => {
        const order = JSON.parse(localStorage.getItem('order'));

        if (order) {
            setOrder(order);
        } else {
            setOrder([]);
        }
    }, [])

    useEffect(() => {
        console.log("🚀 ~ file: DataContext.js ~ line 52 ~ useEffect ~ order", order)

        localStorage.setItem('order', JSON.stringify(order));
    }, [order]);

    return (
        <DataContext.Provider value={{
            session, setSession,
            editProduct, setEditProduct,
            productSelected, setProductSelected,
            order, setOrder,
            totalProduct, setTotalProduct,
            controlDisabled, setControlDisabled
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;