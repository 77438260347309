import http from "./http.service";

class ShadeService {
    
    getAll() {
      return http.get("/products");
    }

    getAllDataSteps(product_id) {
      return http.get(`/configurator/steps/${product_id}`);
    }

    doGetDynamicRequest(url) {
      return http.get(`${url}`);
    }

  }
  
  export default new ShadeService();