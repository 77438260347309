import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";

const PersistLogin = () => {

    const [isLoading, setIsLoading] = useState(true);    
    const { auth, setAuth } = useAuth();

    useEffect((auth) => {
        console.log("🚀 ~ file: PersistLogin.js ~ line 13 ~ useEffect ~ auth", auth)
        let isMounted = true;

        // const verifyRefreshToken = async () => {
        //     try {
        //         await refresh();
        //     }
        //     catch (err) {
        //         console.error(err);
        //     }
        //     finally {
        //         isMounted && setIsLoading(false);
        //     }
        // }

        // persist added here AFTER tutorial video
        // Avoids unwanted call to verifyRefreshToken
        // !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        //tmp 
        const verifyRefreshToken = async () => {
            try {

                let data = JSON.parse(localStorage.getItem("persist"))
                console.log("🚀 ~ file: PersistLogin.js ~ line 37 ~ verifyRefreshToken ~ data", data)
                
                setAuth({...data})


            }
            catch (err) {
                console.error(err);
            }
            finally {
                console.log("🚀 ~ file: PersistLogin.js ~ line 37 ~ verifyRefreshToken ~ finally")
                isMounted && setIsLoading(false);
            }
        }


        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);


        return () => isMounted = false;
    }, [])

    useEffect(() => {
        console.log(`isLoading: ${isLoading}`)
        console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    }, [isLoading])

    return (
        <>
            {isLoading
                ? <p>Loading...</p>
                : <Outlet />
            }
        </>
    )
}

export default PersistLogin