import React, { useState, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Field, useFormik } from "formik";
import * as Yup from "yup";

// import DataContext from '../../context/DataContext';
import useAuth from '../../hooks/useAuth'
import authService from '../../services/auth.service';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required("Required"),
    password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number.")
});

const LoginForm = () => {

    const {setAuth} = useAuth()
    const [message, setMessage] = useState(null);

    // const { setSession } = useContext(DataContext);

    const location = useLocation();
    const navigate = useNavigate();

    const redirectTo =  location.state?.from || "/products";

    console.log("🚀 ~ file: LoginForm.js ~ line 29 ~ LoginForm ~ redirectTo", redirectTo)

    const onSubmit = async (values) => {
        setMessage(null);

        authService.login(values).then((response) => {
            
            setMessage({ error: false, msg: "Login successfully ... redirecting ... " });

            const fullName = response?.fullName;
            const discount = response?.discount
            const accessToken = response?.accessToken;                        
            const roles = response?.roles;
            const state = response?.state;

            console.log("🚀 ~ file: LoginForm.js ~ line 42 ~ authService.login ~ response", response)

            setAuth({...values, fullName, discount, state, roles, accessToken})

            //tmp
            localStorage.setItem("persist", JSON.stringify({ email : values.email ,  fullName, discount, state, roles, accessToken}));

            let timer = setTimeout(() => {
                setMessage(null);
                clearTimeout(timer);
                timer = null;

                navigate(redirectTo);
            }, 2000);


        }).catch((err) => {
            console.log("🚀 ~ file: Login.js ~ line 21 ~ authService.login ~ err", err)
            setMessage({ error: true, msg: err.response.data.message });
            let timer = setTimeout(() => {
                setMessage(null);
                clearTimeout(timer);
                timer = null;
            }, 5000);
        });
    };

    const formik = useFormik({
        initialValues: { email: "", password: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <>
            <h6 className="account-tittle pb-4 border-bottom">SIGN INTO YOUR ACCOUNT</h6>
            <form onSubmit={formik.handleSubmit} className="mt-5">
                {message ?
                    <div class={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                        {message.msg}
                    </div>
                    : null
                }
                <div className="mb-3">
                    <input
                        name="email"
                        type="text"
                        placeholder="Enter your email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.email && formik.touched.email && "error"}`}
                    />
                    {formik.errors.email && formik.touched.email && (
                        <div className="input-feedback">{formik.errors.email}</div>
                    )}
                </div>
                <div className="mb-3">
                    <input
                        name="password"
                        type="password"
                        placeholder="Enter your password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`form-control ${formik.errors.password && formik.touched.password && "error"}`}
                    />
                    {formik.errors.password && formik.touched.password && (
                        <div className="input-feedback">{formik.errors.password}</div>
                    )}
                </div>
                <div className="my-2 d-flex justify-content-between align-items-center">
                    <a href="/forgot-password" className="auth-link text-black">Forgot password?</a>
                    <button type="submit" disabled={!formik.isValid} className="btn btn-abrusci-primary  btn-sm col-md-4">Sign in </button>
                </div>
            </form>
        </>
    )
}

export default LoginForm