import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import DataContext from '../../context/DataContext';
import useAuth from '../../hooks/useAuth';
const Totalbar = () => {

    const { product_id, uid } = useParams();
    const [classFixed, setClassFixed] = useState("bg-total-bar-fixed");

    const { editProduct, setEditProduct } = useContext(DataContext);
    const { productSelected, setProductSelected } = useContext(DataContext);
    const { totalProduct } = useContext(DataContext);
    const { order, setOrder } = useContext(DataContext);
    const { auth } = useAuth();
    const [discount, setDiscount] = useState(0)
    const [total, setTotal] = useState(0)

    const navigate = useNavigate();

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () => window.removeEventListener("scroll", listenToScroll);
    }, []);

    useEffect(() => {        
        setDiscount(0)
        if (auth) {
          setDiscount(Number(auth.discount) ? Number(auth.discount) : 0)
        }

    }, [auth])

    useEffect(() => {                
        let totalDiscount = totalProduct * (discount / 100);
        let total = totalProduct - totalDiscount;

        setTotal(total)

    }, [totalProduct])

    const getOffset = (element) => {
        const elementRect = element?.getBoundingClientRect();
        return elementRect?.bottom;
    }

    const listenToScroll = () => {
        const heightTohide = getOffset(
            document.getElementById('footer-abrusci')
        );

        setClassFixed("bg-total-bar-fixed");
        if ((heightTohide - 148 + 60) <= (window.innerHeight)) {
            setClassFixed("")
        }
    }

    const onClick = (e) => {

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        // To-Do Add fields validation
        if (localProductSelected === null) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Some fields are empty!',
            })
            return;
        }
        // end To-Do

        if (editProduct) {
            console.log("🚀 ~ file: Totalbar.js ~ line 33 ~ onClick ~ editProduct", editProduct);
            editProductToOrder();
        } else {
            console.log("🚀 ~ file: Totalbar.js ~ line 33 ~ onClick ~ editProduct", editProduct);
            addProductToOrder();
        }
    }

    const addProductToOrder = () => {

        let product = JSON.parse(localStorage.getItem('localProductSelected'));
        let currentOrder = [...order];

        currentOrder.push(product);

        setOrder(currentOrder);

        localStorage.removeItem('localProductSelected');
        setProductSelected({});

        navigate('/cart');
    }
    const editProductToOrder = () => {

        let product = JSON.parse(localStorage.getItem('localProductSelected'));
        let currentOrder = [...order];

        let orderEdited = currentOrder.map(p => {
            if (p.uid === product.uid) {
                return { ...p, ...product }
            }
            return p;
        });


        setOrder(orderEdited);

        localStorage.removeItem('localProductSelected');
        setProductSelected({});

        navigate('/cart');
    }

    const totalFormatted = (total) => {
        let formatting_options = {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }
        let dollarString = new Intl.NumberFormat("en-US", formatting_options);

        return dollarString.format(total)
    }

    return (
        <div className={`bg-total-bar ${classFixed} border-bottom`}>
            <div className="d-flex py-2 justify-content-end container">
                <div className="p-2 bd-highlight">
                    <span className='toolbar-total-cart'>Total {totalFormatted(total)}</span>
                </div>
                <button type="button" className="btn btn-toolbar-cart mt-1" onClick={onClick} disabled={total < 1}>Add to cart</button>
            </div>
        </div>
    )
}

export default Totalbar