import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();

    const location = useLocation();

    console.log("🚀 ~ file: RequireAuth.js ~ line 6 ~ RequireAuth ~ auth", auth, auth?.roles, allowedRoles)

    return (
        auth?.roles?.find(role => allowedRoles?.includes(role)) && auth?.state == 'active'
            ? <Outlet />
            : auth?.roles?.find(role => allowedRoles?.includes(role))
                ? <Navigate to="/pending" state={{ from: location }} replace />
                : auth?.email
                    ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                    : <Navigate to="/signin" state={{ from: location }} replace />
    )
}

export default RequireAuth;