import React, { useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DataContext from '../../context/DataContext';

const Text = ({ children }) => {

    const [itemSelected, setItemSelected] = useState({});
    const [valueSelected, setValueSelected] = useState("");

    const { setProductSelected } = useContext(DataContext);

    useEffect(() => {

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        children.forEach(child => {

            if (localProductSelected !== null && Object.keys(localProductSelected).length > 0) {
                //let result = localProductSelected.items.find(e => e.step_id === child.step_id).items.find(e => e.item_id === child.id);

                //setValueSelected((result) ? result.value : '');
                let step = localProductSelected.items.find(e => e.step_id === child.step_id);//.items.find(e => e.item_id === child.id && e.control === child.item_type);

                if (step) {
                    let result = step.items.find(e => e.item_id === child.id && e.control === child.item_type);
                    setValueSelected((result) ? result.value : '');
                } else {
                    setValueSelected("");
                }
            } else {
                setValueSelected("");
            }
        });
    }, []);

    useEffect(() => {

        if (Object.keys(itemSelected).length > 0) {


            const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

            if (localProductSelected !== null) {

                console.log("🚀 ~ file: Text.js:39 ~ useEffect ~ itemSelected", itemSelected)

                let localItems = localProductSelected.items.map(step => {
                    if (step.step_id === itemSelected.step_id) {
                        let items = step.items.map(item => {
                            if (item.item_id === itemSelected.id) {
                                return { ...item, value: valueSelected, item_label_value: valueSelected };
                            }
                            return item;
                        });
                        return { ...step, items: items };
                    }
                    return step;
                });

                localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, current_step: itemSelected.step_id, items: localItems }));
            }
        }
    }, [itemSelected, valueSelected]);

    const onChange = (event, child) => {
        console.log("🚀 ~ file: Text.js:62 ~ onChange ~ event, child", event, child, event.target.value)

        setItemSelected(child);
        setValueSelected(event.target.value)
    }

    return (
        <>
            {children.map(child => (
                <input
                    autoFocus
                    type={child.item_control_type}
                    className="form-control"
                    placeholder="Insert text"
                    key={uuidv4()}
                    // onBlur={e => setProductSelected(JSON.parse(localStorage.getItem('localProductSelected')))}
                    onChange={(e) => onChange(e, child)}
                    value={valueSelected}
                />
            ))}
        </>
    )
}

export default Text;