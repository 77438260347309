import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import useAuth from '../../hooks/useAuth';
// import DataContext from '../../context/DataContext';
import authService from '../../services/auth.service';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required("No password provided.")
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/(?=.*[0-9])/, "Password must contain a number."),
    passwordConfirmation: Yup.string()
        .required("No password provided.")
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
});


const RecoveryPasswordForm = () => {

    const { token } = useParams();

    const {setAuth} = useAuth(); 
    const [message, setMessage] = useState(null);

    // const { setSession } = useContext(DataContext);

    const navigate = useNavigate();


    const onSubmit = async (values) => {
        console.log("🚀 ~ file: RecoveryPasswordForm.js ~ line 17 ~ onSubmit ~ values", values)
        setMessage(null);

        authService.recoveryPassword(values).then((response) => {
            console.log("🚀 ~ file: Login.js ~ line 18 ~ authService.login ~ response", response)

            setMessage({ error: false, msg: "Password has been changed successfully... Redirecting " });
            
            setAuth({})

            //tmp
            localStorage.setItem("persist", JSON.stringify({}));

            let timer = setTimeout(() => {
                setMessage(null);
                clearTimeout(timer);
                timer = null;

                navigate('/signin');
            }, 2000);


        }).catch((err) => {
            console.log("🚀 ~ file: Login.js ~ line 21 ~ authService.login ~ err", err)
            setMessage({ error: true, msg: err.response.data.message });
            let timer = setTimeout(() => {
                setMessage(null);
                clearTimeout(timer);
                timer = null;
            }, 5000);
        });
    };

    const formik = useFormik({
        initialValues: { token: token, password: "", passwordConfirmation: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <div className="">
            <div className="container-title-header">
                <h2 className="account-signin-title mb-0 text-center">Recovery Password</h2>
            </div>
            <div className="container sigin-container ">
                <div className="d-flex justify-content-center">
                    <div className="col-md-5 py-5"  >
                        <h6 className="account-tittle pb-4 border-bottom">Enter your new password</h6>
                        <form onSubmit={formik.handleSubmit} className="mt-3">
                            <h6 className="mb-3">
                                Please type your new password
                            </h6>
                            {message ?
                                <div class={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                    {message.msg}
                                </div>
                                : null
                            }
                            <div className="mb-3">
                                <input
                                    name="password"
                                    type="password"
                                    placeholder="Enter your password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={`form-control ${formik.errors.password && formik.touched.password && "error"}`}
                                />
                                {formik.errors.password && formik.touched.password && (
                                    <div className="input-feedback">{formik.errors.password}</div>
                                )}
                            </div>
                            <div className="mb-3">
                                <p>
                                    <small>Password must be 8 or more characters long.
                                        Avoid words, numbers and phrases associated with you.</small>
                                </p>
                                <input
                                    name="passwordConfirmation"
                                    type="password"
                                    placeholder="Confirm password"
                                    value={formik.values.passwordConfirmation}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={`form-control ${formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && "error"}`}
                                />
                                {formik.errors.passwordConfirmation && formik.touched.passwordConfirmation && (
                                    <div className="input-feedback">{formik.errors.passwordConfirmation}</div>
                                )}
                            </div>
                            <div className="my-2 d-flex justify-content-between align-items-center">
                                <button type="submit" disabled={!formik.isValid} className="btn btn-abrusci-primary  btn-sm col-md-6">Recovery password </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RecoveryPasswordForm