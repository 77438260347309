import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DataContext from '../../context/DataContext';
import ShadesService from '../../services/shades.service';

const Radio = ({ steps, setSteps, step, children }) => {

    const [searcher, setSearcher] = useState('')
    const [filteredItems, setFilteredItems] = useState(children)
    const [totalItems, setTotalItems] = useState(children.length)
    const [valueSelected, setValueSelected] = useState({});

    const { setProductSelected } = useContext(DataContext);

    const { controlDisabled, setControlDisabled } = useContext(DataContext);

    const onChange = (child) => {
        console.log("🚀 ~ file: Radio.js ~ line 10 ~ onChange ~ child", child, valueSelected);
        if (child?.item_disabled) {
            return
        }

        let parentStep = {};
        let url = ""
        let item_execute_function = child.item_execute_function ? child.item_execute_function : "";

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {

            let localItems = localProductSelected.items.map(step => {
                if (step.step_id === child.step_id) {
                    parentStep = step;
                    url = step.get_data_url;
                    let items = step.items.map(item => {
                        if (item.step_id === child.step_id && item.control === child.item_type) {
                            return { 
                                ...item, item_id: child.id, 
                                value: child.item_name, 
                                image: child.item_image, 
                                item_label_value: child.item_name, 
                                item_label_formula: (child.item_label_formula) ? child.item_label_formula : item.item_label_formula
                            };
                        }
                        return item;
                    });
                    return { ...step, items: items };
                }
                return step;
            });
            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, current_step: child.step_id, items: localItems }));
            dynamicCalculationPrice(child);
        }

        if (Object.keys(parentStep).length > 0 && parentStep.execute_function.includes('setValanceColor')) {
            dynamicValanceColor(child)
        }

        if (Object.keys(parentStep).length > 0 && parentStep.execute_function.includes('getFabricColor')) {
            dynamicFabricColor(child)
        }

        if (item_execute_function.includes('dynamicEnabledDisabledMultichannelControl')) {
            dynamicEnabledDisabledControl(child, ['manual', 'plugin'], ['solar_panel']);
        }

        if (item_execute_function.includes('dynamicEnabledDisabledCrankControl')) {
            dynamicEnabledDisabledControl(child, ['motorized'], ['crank']);
        }

        if (item_execute_function.includes("dynamicFasciaColor")) {
            dynamicFasciaColor(child);
        }

        if (item_execute_function.includes("dynamicFasciaCalculation")) {
            dynamicFasciaCalculation(child);
            dynamicFasciaImage(child);
        }

        if (item_execute_function.includes("dynamicEnabledDisabledOrientation")) {
            console.log("🚀 ~ file: Radio.js:67 ~ onChange ~ item_execute_function", steps, child)
            dynamicEnabledDisabledItem(child, ['with_fascia'], ['orientation_outside'], ['orientation_inside']);
        }


        if (item_execute_function.includes("dynamicAccesoriesCalculation")) {
            dynamicAccesoriesCalculation(url, child)
        }





        setValueSelected(child);

        // localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));
        setProductSelected(JSON.parse(localStorage.getItem('localProductSelected')));
    }

    const isSelected = (child) => {

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null && Object.keys(localProductSelected).length > 0) {
            let step = localProductSelected.items.find(e => e.step_id === child.step_id);//.items.find(e => e.item_id === child.id && e.control === child.item_type);

            if (step) {
                let result = step.items.find(e => e.item_id === child.id && e.control === child.item_type);
                if (result) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
        return false;
    }

    const dynamicCalculationPrice = async (child) => {

        if (child.item_increase_price === 1) {
            let total = Number(child.item_price_increase);

            const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

            if (localProductSelected !== null) {

                let localItems = localProductSelected.items.map(step => {
                    if (step.step_id === child.step_id) {
                        let items = step.items.map(item => {
                            if (item.step_id === child.step_id && item.control === child.item_type) {
                                return { ...item, total: total };
                            }
                            return item;
                        });
                        return { ...step, items: items };
                    }
                    return step;
                });
                localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
                setProductSelected({ ...localProductSelected, items: localItems });
            }
        }
    }

    const dynamicEnabledDisabledControl = async (child, labels_conditionals, controls_to_enabled_disabled) => {
        console.log("🚀 ~ file: Radio.js ~ line 160 ~ dynamicEnabledDisabledControl ~ child, labels_conditionals, controls_to_enabled_disabled", child, labels_conditionals, controls_to_enabled_disabled)

        let disableControl = (labels_conditionals.includes(child.item_label_formula)) ? true : false;


        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {

            let localItems = localProductSelected.items.map(step => {
                if (step.step_id === child.step_id) {
                    let items = step.items.map(item => {
                        if (item.step_id === child.step_id && controls_to_enabled_disabled.includes(item.item_label_formula)) {
                            return { ...item, disabled: disableControl, value: disableControl ? '' : item.value, total: disableControl ? 0 : item.total };
                        }
                        return item;
                    });
                    return { ...step, items: items };
                }
                return step;
            });
            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            setProductSelected({ ...localProductSelected, items: localItems });
        }
    }

    const dynamicEnabledDisabledItem = async (child, labels_conditionals, controls_to_enabled_disabled, replace_value) => {
        console.log("🚀 ~ file: Radio.js ~ line 160 ~ dynamicEnabledDisabledControl ~ child, labels_conditionals, controls_to_enabled_disabled", steps)

        let disableControl = (labels_conditionals.includes(child.item_label_formula)) ? true : false;
        let newItem;
        let newSteps = steps.map(step => {
            let items = step.items.map(item => {
                let children = item.children.map(_child => {
                    if (replace_value.includes(_child.item_label_formula)) {
                        newItem = _child;
                    }

                    if (controls_to_enabled_disabled.includes(_child.item_label_formula)) {
                        return { ..._child, item_disabled: disableControl };
                    }
                    return _child
                })
                return { ...item, children };
            })

            return { ...step, items };
        })

        setSteps(newSteps);

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {
            let localItems = localProductSelected.items.map(step => {
                if (step.step_id === newItem.step_id) {
                    let items = step.items.map(item => {
                        if (item.step_id === newItem.step_id) {
                            return { ...item, item_id: newItem.id, value: newItem.item_name };
                        }
                        return item;
                    });
                    return { ...step, items: items };
                }
                return step;
            });
            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            setProductSelected({ ...localProductSelected, items: localItems });
        }
    }

    const dynamicFasciaCalculation = async (child) => {
        console.log("🚀 ~ file: Radio.js ~ line 128 ~ dynamicFasciaCalculation ~ child", child);

        // Formula = ((Width+Fraction)/12)*10.5
        // 30201 + 30202 

        let total = 0;

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {

            if (child.item_name === 'Yes') {
                localProductSelected.items.forEach(step => {
                    step.items.forEach(item => {
                        if (item.item_label_formula === 'width' || item.item_label_formula === 'fraction_width') {
                            console.log("🚀 ~ file: Radio.js ~ line 145 ~ dynamicFasciaCalculation ~ item", Number(item.value));
                            total += (isNaN(item.value) ? 0 : Number(item.value));
                        }
                    });
                });

                total = ((total / 12) * 10.5);
                total = total.toFixed(2);

            }

            console.log("🚀 ~ file: Radio.js ~ line 151 ~ dynamicFasciaCalculation ~ total", total)

            let localItems = localProductSelected.items.map(step => {
                if (step.step_id === child.step_id) {
                    return { ...step, total: total }
                }
                return step
            });

            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            setProductSelected({ ...localProductSelected, items: localItems });

        }

    }

    const dynamicFasciaColor = async (child) => {
        let colorName = child.item_name;
        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {

            let localItems = localProductSelected.items.map(step => {
                let items = step.items.map(item => {
                    if (item.item_label_formula === 'with_fascia') {
                        let image = ""
                        let splitImage = item.image.split("-");
                        if (splitImage.length >= 2) {
                            splitImage.pop();
                            splitImage.push(`${colorName.toLowerCase()}.png`)
                            image = splitImage.join("-")
                        }
                        return { ...item, image: image };
                    }
                    return item;
                });
                return { ...step, items: items };
            });
            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            setProductSelected({ ...localProductSelected, items: localItems });
        }
    }

    const dynamicFasciaImage = async (child) => {
        console.log("🚀 ~ file: Radio.js:254 ~ dynamicFasciaImage ~ child:", child)
        let colorName = "";
        let image = child.item_image;

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {
            localProductSelected.items.forEach(step => {
                step.items.forEach(item => {
                    if (item.item_label_formula === 'component-color') {
                        colorName = item.value.toLowerCase()
                    }
                });
            });

            let localItems = localProductSelected.items.map(step => {
                let items = step.items.map(item => {
                    if (item.item_id === child.id) {
                        image = image.replace("?", colorName)
                        return { ...item, image: image };
                    }
                    return item;
                });
                return { ...step, items: items };
            });
            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            setProductSelected({ ...localProductSelected, items: localItems });
        }
    }

    const dynamicValanceColor = async (child) => {


        let colorName = child.item_name;
        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {

            let localItems = localProductSelected.items.map(step => {
                let items = step.items.map(item => {
                    if (item.item_label_formula === 'valance-color') {
                        let image = ""
                        let splitImage = item.image.split("-");
                        if (splitImage.length >= 2) {
                            splitImage.pop();
                            splitImage.push(`${colorName.toLowerCase()}.png`)
                            image = splitImage.join("-")
                        }
                        return { ...item, image: image };
                    }
                    return item;
                });
                return { ...step, items: items };
            });
            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            setProductSelected({ ...localProductSelected, items: localItems });
        }
    }

    const dynamicFabricColor = async (child) => {
        console.log("🚀 ~ file: Radio.js ~ line 276 ~ dynamicFabricColor ~ child", child)
        let colorName = "";
        let image = child.item_image;

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {
            localProductSelected.items.forEach(step => {
                step.items.forEach(item => {
                    if (item.item_label_formula === 'fabric-color') {
                        colorName = item.value.toLowerCase()
                    }
                });
            });

            console.log("🚀 ~ file: Radio.js ~ line 276 ~ dynamicFabricColor ~ child", child, colorName)

            let localItems = localProductSelected.items.map(step => {
                let items = step.items.map(item => {
                    if (item.item_id === child.id) {
                        image = image.replace("?", colorName)
                        return { ...item, image: image };
                    }
                    return item;
                });
                return { ...step, items: items };
            });
            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            setProductSelected({ ...localProductSelected, items: localItems });
        }
    }



    const dynamicAccesoriesCalculation = (url, child) => {
        return new Promise(async (resolve, reject) => {
            let width = 0;
            let total = 0;
            const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

            if (localProductSelected !== null) {

                if (['YES'].includes(child.item_name)) {
                    localProductSelected.items.forEach(step => {
                        step.items.forEach(item => {
                            if (item.item_label_formula === 'width' || item.item_label_formula === 'fraction_width') {
                                width += (isNaN(item.value) ? 0 : Number(item.value));
                            }
                        });
                    });
                }

                if (width > 0) {
                    url = url.replace('?', width)
                    const response = await ShadesService.doGetDynamicRequest(url);
                    total = response.data
                }

                console.log("🚀 ~ file: Radio.js ~ line 254 ~ dynamicAccesoriesHoodCalculation ~ width", url, width, total)

                let localItems = localProductSelected.items.map(step => {
                    if (step.step_id === child.step_id) {
                        return { ...step, total: total };
                    }
                    return step;
                });


                localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
                setProductSelected(JSON.parse(localStorage.getItem('localProductSelected')));

            }
            resolve();
        });
    }

    const onHandleInputChange = (event) => {

        let searcher = event.target.value

        setSearcher(searcher)

        let filteredItems = children.filter(child => {
            let name = child.item_name.toLowerCase()
            return name.includes(searcher.toLowerCase())
        })

        setFilteredItems(filteredItems)
        setTotalItems(filteredItems.length)
    }

    return (
        <>
            {step?.step_enable_searcher == 1 && (
                <div className="pb-3">
                    <input type="text" value={searcher} placeholder='search' onChange={onHandleInputChange} />
                    <span className='mx-3'>result {totalItems} items</span>
                </div>
            )}
            {filteredItems.map(child => (
                <div className={`col-${(child.item_col_sm_size)} col-lg-${child.item_col_size} py-2 text-center ${child?.item_disabled ? 'item-disabled' : ""} ${isSelected(child) ? "item-selected" : ""}`} key={uuidv4()} onClick={() => onChange(child)}>
                    <div className="col">
                        <img src={child.item_image_placeholder} alt="" style={{ width: child.item_image_placeholder_width }} />
                    </div>
                    <div className="col">
                        <p className="text-break">{child.item_name}</p>
                    </div>
                </div>
            ))}
        </>
    )
}

export default Radio;