import http from "./http.service";

class AuthService {


    login(data) {
        console.log("🚀 ~ file: auth.service.js ~ line 7 ~ AuthService ~ login ~ data", data)

        return http.post("/wholesaler/auth", data).then(response => {

            // if (response.data.status === 1) {
            //     localStorage.setItem("user", JSON.stringify(response.data.data));
            // }

            return response.data;
        })
    }

    register(data) {
        console.log("🚀 ~ file: auth.service.js ~ line 20 ~ AuthService ~ register ~ data", data);

        return http.post("/wholesaler/signup", data).then(response => {

            if (response.data.status === 1) {
                localStorage.setItem("user", JSON.stringify(response.data.data));
            }

            return response.data;
        })
    }

    forgotPassword(data) {
        console.log("🚀 ~ file: auth.service.js ~ line 33 ~ AuthService ~ recoveryPassword ~ email", data)

        return http.get(`/wholesaler/passrecovery/${data.email}`);
    }

    recoveryPassword(data) {
        console.log("🚀 ~ file: auth.service.js ~ line 33 ~ AuthService ~ recoveryPassword ~ email", data);

        return http.post(`/wholesaler/recover/`, data);
    }

}

export default new AuthService();