import React, { useEffect, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DataContext from '../../context/DataContext';
import ShadesService from '../../services/shades.service';

const Select = ({ steps, setSteps, step, children }) => {

    const [dataFetched, setDataFetched] = useState(false);
    const [items, setItems] = useState([]);
    const [itemSelected, setItemSelected] = useState({});
    const [valueSelected, setValueSelected] = useState("");
    const [labelValueSelected, setLabelValueSelected] = useState("");
    const [productItemsSelected, setProductItemsSelected] = useState({});

    const { productSelected, setProductSelected } = useContext(DataContext);


    // useEffect(() => {
    //     setProductItemsSelected(productSelected)
    // }, [productSelected]);

    useEffect(() => {
        console.log("🚀 ~ file: Select.js ~ line 22 ~ useEffect ~ dataFetched", dataFetched)
        if (!dataFetched) {
            loadItems();
            setDataFetched(true);
        }
    }, []);


    useEffect(() => {

        functionsHandler().then(() => loadItems());

    }, [itemSelected, valueSelected]);


    const loadItems = () => {

        let res = children.map(child => {

            let defaultValue = getDefaultValue(child);
            let disabled = checkIsDisabled(child)
            let item_values_disabled = getItemValuesDisabled(child)
            return { ...child, defaultValue: defaultValue, disabled: disabled, item_values_disabled }
        });

        setItems([...res]);
    }


    const functionsHandler = async () => {

        try {

            let updateResponse = await updateValueSelected();
            let executionResponse = await functionsExecution();

            setProductSelected(JSON.parse(localStorage.getItem('localProductSelected')));

            return true;
        } catch (error) {
            console.log("🚀 ~ file: Select.js ~ line 85 ~ functionsHandler ~ error", error)
            return false;
        }

    }

    const updateValueSelected = () => {

        return new Promise((resolve, reject) => {

            if (Object.keys(itemSelected).length > 0) {
                console.log("🚀 ~ file: Select.js:73 ~ returnnewPromise ~ itemSelected", itemSelected, valueSelected)

                const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

                if (localProductSelected !== null) {

                    let localItems = localProductSelected.items.map(step => {
                        if (step.step_id === itemSelected.step_id) {

                            let items = step.items.map(item => {
                                if (item.item_id === itemSelected.id && item.control === itemSelected.item_type) {
                                    return { ...item, value: valueSelected, item_label_value: labelValueSelected };
                                }
                                return item;
                            });

                            return { ...step, items: items };
                        }

                        return step;
                    });

                    localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, current_step: itemSelected.step_id, items: localItems }));
                }
            }

            console.log("🚀 ~ file: Select.js ~ line 119 ~ updateValueSelected ~ resolve")

            resolve(true);
        });
    }

    const functionsExecution = () => {

        return new Promise(async (resolve, reject) => {

            if (Object.keys(itemSelected).length > 0) {

                const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

                let step = localProductSelected.items.find(el => el.step_id === itemSelected.step_id);

                if (step) {

                    let isValid = true;
                    let width = 0;
                    let height = 0;

                    step.items.forEach(item => {

                        if (step.execute_function.includes('dynamicMeasurementCalculationPrice')) {
                            if (step.get_data === 1 && item.is_required === 1) {
                                if (item.item_id === itemSelected.id && item.control === itemSelected.item_type) {
                                    if (valueSelected === "") {
                                        isValid = false;
                                    } else {

                                        if (item.item_label_formula === 'width' || item.item_label_formula === 'fraction_width') {
                                            width += isNaN(valueSelected) ? 0 : Number(valueSelected);
                                        }

                                        if (["height", "projection", "fraction_height", "fraction_projection"].includes(item.item_label_formula)) {
                                            height += isNaN(valueSelected) ? 0 : Number(valueSelected);
                                        }
                                    }
                                } else if (item.value === "" || item.value == null) {
                                    isValid = false;
                                } else {

                                    if (item.item_label_formula === 'width' || item.item_label_formula === 'fraction_width') {
                                        width += isNaN(item.value) ? 0 : Number(item.value);
                                    }

                                    if (["height", "projection", "fraction_height", "fraction_projection"].includes(item.item_label_formula)) {
                                        height += isNaN(item.value) ? 0 : Number(item.value);
                                    }

                                }
                            }
                        }
                    });

                    if (step.execute_function.includes('dynamicMeasurementCalculationPrice')) {
                        if (step.get_data === 1 && isValid) {
                            let calculationResponse = await dynamicMeasurementCalculationPrice(step.step_id, step.get_data_url, [width, height]);
                        }
                    }

                    if (step.execute_function.includes('dynamicFasciaCalculation')) {
                        let calculationFasciaResponse = await dynamicFasciaCalculation(width);
                    }


                    if (step.execute_function.includes('dynamicAccesoriesCalculation')) {
                        let calculationHoodAccesoriesResponse = await dynamicAccesoriesCalculation(width, 'accesories-hood', 'YES');
                        let calculationCeillingAccesoriesResponse = await dynamicAccesoriesCalculation(width, 'accesories-ceilling', 'YES');
                    }


                    if (["dynamicMultichannelCalculationPrice", "dynamicCrankCalculationPrice", "dynamicSolarAccessoriesCalculationPrice"].includes(itemSelected.item_execute_function)) {
                        let calculationMultichannelResponse = await dynamicCalculationPrice(itemSelected, valueSelected)
                    }

                    if (["dynamicEnableDisableValuesProjection"].includes(itemSelected.item_execute_function)) {
                        let itemValue = isNaN(valueSelected) ? 0 : Number(valueSelected);
                        let labelFormula = "projection";
                        let valuesToDisable = (itemValue > 16) ? ["10"] : [];
                        let _ = await dynamicEnableDisableValuesInWidthOrProjection(itemSelected, valueSelected, labelFormula, valuesToDisable)
                    }

                    if (["dynamicEnableDisableValuesWidth"].includes(itemSelected.item_execute_function)) {
                        let itemValue = isNaN(valueSelected) ? 0 : Number(valueSelected);
                        let labelFormula = "width";
                        let valuesToDisable = (itemValue >= 10) ? [17, 18, 19, 20] : [];
                        let _ = await dynamicEnableDisableValuesInWidthOrProjection(itemSelected, valueSelected, labelFormula, valuesToDisable)
                    }
                }
                resolve(true);
            }

            reject(false);
        });
    }

    const getDefaultValue = (child) => {

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {
            //let result = localProductSelected.items.find(e => e.step_id === child.step_id).items.find(e => e.item_id === child.id && e.control === child.item_type);

            let step = localProductSelected.items.find(e => e.step_id === child.step_id);

            if (step) {
                let result = step.items.find(e => e.item_id === child.id && e.control === child.item_type);
                return (result) ? result.value : '';
            } else {
                return ''
            }


            // return (result) ? result.value : '';
        } else {
            return "";
        }
    }

    const checkIsDisabled = (child) => {

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {

            // let res = localProductSelected.items.find(e => e.step_id === child.step_id).items.find(e => e.item_id === child.id);

            // if (res) {
            //     return res.disabled;
            // }

            let step = localProductSelected.items.find(e => e.step_id === child.step_id);

            if (step) {
                let result = step.items.find(e => e.item_id === child.id);
                return (result) ? result.disabled : false;
            } else {
                return false
            }
        }

        return false;
    }

    const getItemValuesDisabled = (child) => {

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {
            
            let step = localProductSelected.items.find(e => e.step_id === child.step_id);

            if (step) {
                let result = step.items.find(e => e.item_id === child.id && e.control === child.item_type);
                return (result) ? result.item_values_disabled : [];
            } else {
                return []
            }
        } else {
            return [];
        }
    }

    const onChange = (event, child) => {

        var index = event.target.selectedIndex;
        var optionElement = event.target.childNodes[index]

        setItemSelected(child);
        setLabelValueSelected(optionElement.getAttribute('data-label-value'))
        setValueSelected(event.target.value);
    }

    const dynamicMeasurementCalculationPrice = (step_id, url, params) => {
        console.log("🚀 ~ file: Select.js ~ line 229 ~ dynamicMeasurementCalculationPrice ~ params", params)

        return new Promise(async (resolve, reject) => {

            let splitURL = url.split("?");

            let newURL = "";
            splitURL.forEach((element, idx) => {
                let str = (params[idx]) ? params[idx] : "";
                newURL += element + str;
            });

            console.log("🚀 ~ file: Select.js ~ line 240 ~ returnnewPromise ~ newURL", newURL)
            const response = await ShadesService.doGetDynamicRequest(newURL);
            console.log("🚀 ~ file: Select.js ~ line 251 ~ returnnewPromise ~ response", response)


            let total = response.data;

            const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

            if (localProductSelected !== null) {
                let localItems = localProductSelected.items.map(step => {
                    if (step.step_id === step_id) {
                        return { ...step, total: total }
                    }
                    return step
                });

                localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            }
            console.log("🚀 ~ file: Select.js ~ line 268 ~ dynamicMeasurementCalculationPrice ~ resolve")
            resolve();
        });

    }

    const dynamicFasciaCalculation = (width) => {

        return new Promise(async (resolve, reject) => {

            if (width > 0) {
                // Formula = ((Width+Fraction)/12)*10.5
                let step_id = 0;
                let fasciaCalculation = false;
                let total = ((width / 12) * 10.5);
                total = total.toFixed(2);

                const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

                if (localProductSelected !== null) {

                    localProductSelected.items.forEach(step => {
                        step.items.forEach(item => {
                            if (item.item_label_formula === 'fascia' && item.value === 'Yes') {
                                step_id = item.step_id;
                                fasciaCalculation = true;
                            }
                        });
                    });



                    let localItems = localProductSelected.items.map(step => {
                        if (step.step_id === step_id && fasciaCalculation) {

                            return { ...step, total: total };
                        }
                        return step;
                    });


                    localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
                }
            }
            console.log("🚀 ~ file: Select.js ~ line 312 ~ dynamicFasciaCalculation ~ resolve")
            resolve();

        });

    }

    const dynamicAccesoriesCalculation = (width, label_form, value) => {

        return new Promise(async (resolve, reject) => {

            if (width > 0) {
                let step_id = 0;
                let url = "";
                let total = 0;

                const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

                if (localProductSelected !== null) {

                    localProductSelected.items.forEach(step => {
                        step.items.forEach(async item => {
                            if (item.item_label_formula === label_form && step.get_data_url.length > 0 && item.value == value) {
                                url = step.get_data_url.replace('?', width)
                                step_id = item.step_id;
                            }
                        });
                    });

                    if (url != "") {
                        const response = await ShadesService.doGetDynamicRequest(url);
                        total = response.data
                    }

                    let localItems = localProductSelected.items.map(step => {
                        if (step.step_id === step_id) {
                            return { ...step, total: total };
                        }
                        return step;
                    });

                    localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
                }
            }
            resolve();
        });

    }

    const dynamicCalculationPrice = (child, value) => {

        return new Promise(async (resolve, reject) => {

            let options = child.options;

            let response = options.filter(e => e.value === value);
            let total = 0;
            if (response.length > 0) {
                let item = response[0];
                total = Number(item.price);
            }

            const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

            if (localProductSelected !== null) {

                let localItems = localProductSelected.items.map(step => {
                    if (step.step_id === child.step_id) {
                        let items = step.items.map(item => {
                            if (item.step_id === child.step_id && item.control === child.item_type) {
                                return { ...item, total: total };
                            }
                            return item;
                        });
                        return { ...step, items: items };
                    }
                    return step;
                });
                localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
            }

            resolve()
        });
    }

    const dynamicEnableDisableValuesInWidthOrProjection = (child, value, labelToDisable, valuesToDisable) => {

        let labelFormula = labelToDisable;
        let valuesToDisableInProjection = valuesToDisable;

        let newSteps = steps.map(step => {
            let items = step.items.map(item => {
                let children = item.children.map(_child => {
                    if (_child.item_label_formula === labelFormula) {
                        return { ..._child, item_values_disabled: valuesToDisableInProjection };
                    }
                    return _child
                })
                return { ...item, children };
            })
            return { ...step, items };
        })

        const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));

        if (localProductSelected !== null) {
            let localItems = localProductSelected.items.map(step => {
                let items = step.items.map(item => {
                    if (item.item_label_formula === labelFormula) {
                        return { ...item, item_values_disabled: valuesToDisableInProjection };
                    }
                    return item;
                });
                return { ...step, items };
            });

            localStorage.setItem('localProductSelected', JSON.stringify({ ...localProductSelected, items: localItems }));
        }

        setSteps(newSteps);
    }


    return (
        <>
            {items.map(child => (
                <div className={`col-md-${child.item_col_size} px-1`}>
                    <select className={`form-select`} aria-label="select" key={uuidv4()} onChange={(e) => onChange(e, child)} defaultValue={child.defaultValue} disabled={child.disabled}>
                        <option key={uuidv4()} value="">- select an option -</option>
                        {child.options.map((element, index) => <option key={index} value={element.value} data-label-value={element.name} disabled={(child.item_values_disabled && child.item_values_disabled.includes(element.value))} >{element.name}</option>)}
                    </select>
                </div>
            ))}
        </>
    )
}


export default Select;