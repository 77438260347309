import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Totalbar from '../cart/Totalbar';

const Footer = () => {

  const location = useLocation();
  const [showTotalbar, setShowTotalbar] = useState(false);

  useEffect(() => {
    setShowTotalbar(false);
    if (location.pathname.startsWith('/items')) {
      setShowTotalbar(true);
    }
  }, [location]);

  return (
    <footer id='footer-abrusci' className='bg-footer mt-auto'>
      {(showTotalbar) ? <Totalbar /> : ''}
      <div  className="container d-flex flex-wrap align-items-center py-2 my-3">
        <p className="col-md-4 mb-0">
          <img className="icon-logo mx-3" src="/logo.png" alt="" />
          © 2022, Deco Abrusci
        </p>
        <ul className="nav col-md-8 justify-content-start">
          <li className="nav-item"><a href="#" className="nav-link px-2">info@decoabrusci.us</a></li>
          <li className="nav-item"><a href="#" className="nav-link px-2">2290 W 8th Ave, Hialeah, FL 33010</a></li>
        </ul>
      </div>
    </footer>

  )
}

export default Footer