import React from 'react'
import { useEffect } from 'react'
import useAuth from '../../hooks/useAuth'

const Unauthorized = () => {
    const { setAuth } = useAuth();
    useEffect(() => {
        setAuth({})
        localStorage.setItem("persist", JSON.stringify({}));
    }, [])

    return (
        <div className="">
            <div className="container ">
                <div className="row p-1 row-cols-1 row-cols-lg-1">
                    <div className="feature col p-5 text-center"  >
                        <div>
                            <i class="fa-solid fa-triangle-exclamation"></i>
                        </div>
                        <div>
                            <h3>
                                Unauthorized
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unauthorized