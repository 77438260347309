import React, { useState } from 'react'
import { Field, useFormik } from "formik";
import * as Yup from "yup";

import authService from '../../services/auth.service';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email()
        .required("Required")
});
const ForgotPasswordForm = () => {

    const [message, setMessage] = useState(null);

    const onSubmit = async (values) => {
        console.log("🚀 ~ file: ForgotPasswordForm.js ~ line 17 ~ onSubmit ~ values", values)
        setMessage(null);

        authService.forgotPassword(values).then((response) => {
            console.log("🚀 ~ file: Login.js ~ line 18 ~ authService.login ~ response", response)
            setMessage({ error: false, msg: "You will recive a email with instructions to recovery password " });
        }).catch((err) => {
            console.log("🚀 ~ file: Login.js ~ line 21 ~ authService.login ~ err", err)
            setMessage({ error: true, msg: err.response.data.message });
            let timer = setTimeout(() => {
                setMessage(null);
                clearTimeout(timer);
                timer = null;
            }, 5000);
        });
    };

    const formik = useFormik({
        initialValues: { email: "" },
        validateOnBlur: true,
        onSubmit,
        validationSchema: validationSchema,
    });

    return (
        <div className="">
            <div className="container-title-header">
                <h2 className="account-signin-title mb-0 text-center">Forgot Password</h2>
            </div>
            <div className="container sigin-container ">
                <div className="d-flex justify-content-center">
                    <div className="col-md-5 py-5"  >
                        <h6 className="account-tittle pb-4 border-bottom">Forgot your password?</h6>
                        <form onSubmit={formik.handleSubmit} className="mt-3">
                            <h6 className="mb-3">
                                Enter your email and we’ll help you reset your password.
                            </h6>
                            {message ?
                                <div class={`alert alert-${message.error ? 'danger' : 'success'}`} role="alert">
                                    {message.msg}
                                </div>
                                : null
                            }
                            <div className="mb-3">
                                <input
                                    name="email"
                                    type="text"
                                    placeholder="Enter your email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={`form-control ${formik.errors.email && formik.touched.email && "error"}`}
                                />
                                {formik.errors.email && formik.touched.email && (
                                    <div className="input-feedback">{formik.errors.email}</div>
                                )}
                            </div>
                            <div className="my-2 d-flex justify-content-between align-items-center">
                                <button type="submit" disabled={!formik.isValid} className="btn btn-abrusci-primary  btn-sm col-md-5">Reset password </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordForm