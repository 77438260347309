import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import ShadeService from '../../services/shades.service';
import DataContext from '../../context/DataContext';
import useAuth from "../../hooks/useAuth";

const Header = ({ allowedRoles }) => {

    const [shades, setShades] = useState([]);
    const [totalProducts, setTotalProducts] = useState(0);
    // const { session, setSession } = useContext(DataContext);
    const { order } = useContext(DataContext);
    const { auth, setAuth } = useAuth();
    console.log("🚀 ~ file: Header.js ~ line 15 ~ Header ~ auth", auth)


    const location = useLocation();
    const navigate = useNavigate();

    const logoutRedirectTo = "/signin";

    useEffect(() => {
        getProducts();
    }, []);


    useEffect(() => {
        setTotalProducts(order.length);
    }, [order]);


    const isPathActive = (path) => {
        return location.pathname.startsWith(path);
    }

    const getProductsLink = (product) => {
        let path = location.pathname.split('/');

        if (!path.find(el => el === product.id.toString())) {
            return (
                <li className="dropdown-item header-products-link">
                    <a onClick={() => onClickToProduct(product)} className={'nav-link'}> {product.name} </a>
                </li>
            );
        }
    }

    const onClickToProduct = (product) => {
        navigate(`/items/${product.id}`);
        window.location.reload();
    }

    const onLogout = () => {
        Swal.fire({
            title: 'Do you want close session?',
            text: "",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#b49f96',
            cancelButtonColor: '#9599a4',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem('user');
                setAuth({})
                localStorage.setItem("persist", JSON.stringify({}));
                navigate(logoutRedirectTo)
            }
        });
    }

    // API 
    const getProducts = () => {
        ShadeService.getAll().then(response => {

            if (response.status === 200) {
                setShades(response.data)
            }
        }).catch(e => {
            console.log(e);
        });
    }


    return (
        <header className="bg-light">
            <div className=" container d-flex flex-wrap justify-content-center">
                <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                    {/* <svg className="bi me-2" width="40" height="32"><use xlink:href="#bootstrap"></use></svg> */}
                    <img className="logo" src="/logo.png" alt="" />
                </a>

                <ul className="nav nav-pills py-3">
                    {
                        auth?.roles?.find(role => allowedRoles?.includes(role)) && auth?.state == 'active'
                            ? (
                                <>
                                    {/* <li className="nav-item mx-3">
                                        <Link to={'/shades'} className={isPathActive('/shades') ? 'nav-link active' : 'nav-link'}>
                                            Shades
                                        </Link>
                                    </li> */}
                                    <li class="nav-item dropdown">                                        
                                        <Link
                                            to={'/products'}
                                            className={`nav-link dropdown-toggle ${isPathActive('/items') ? 'active' : ''}`}
                                            // href="#" 
                                            // role="button" 
                                            // data-bs-toggle="dropdown" 
                                            // aria-expanded="false"
                                        >
                                            Products
                                        </Link>
                                        <ul class="dropdown-menu">
                                            {
                                                shades.map((product, index) => (
                                                    getProductsLink(product)
                                                ))
                                            }
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={'/cart'} className={isPathActive('/cart') ? 'position-relative nav-link active' : 'position-relative nav-link'}>
                                            <i class="fa-solid fa-cart-shopping"></i>
                                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-nav">
                                                {totalProducts}
                                            </span>
                                        </Link>
                                    </li>
                                    <li className="nav-item ms-2">
                                        <Link to={'#'} className={`nav-link dropdown-toggle`} id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-user ms-2"></i>
                                        </Link>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a class="dropdown-item">{auth.fullName}</a></li>
                                            <li><a class="dropdown-item" onClick={onLogout}>Logout</a></li>
                                        </ul>
                                    </li>
                                </>
                            )
                            : (
                                <li className="nav-item mx-2">
                                    <Link to={'/signin'} className={isPathActive('/signin') ? 'nav-link active' : 'nav-link'}>
                                        Sign in
                                    </Link>
                                </li>
                            )
                    }
                </ul>
            </div>
        </header>
    )
}

export default Header