import React from 'react'

const Unauthorized = () => {
    return (
        <div className="">
            <div className="container ">
                <div className="row p-1 row-cols-1 row-cols-lg-1">
                    <div className="feature col p-5 text-center"  >
                        <div>
                            
                        </div>
                        <div>
                            <h3>
                                We are currently processing your application
                            </h3>
                            <p>
                                We are currently processing your application, we will notify you via email once you have been approved by our management.
                                Thank you for your interest
                                Deco Abrusci Team
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unauthorized