import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import ShadeService from '../../services/shades.service';

const Shades = () => {

  const [shades, setShades] = useState([]);

  const { editProduct, setEditProduct } = useContext(DataContext);
  const { productSelected, setProductSelected } = useContext(DataContext);

  useEffect(() => {

    setEditProduct(false);
    localStorage.removeItem('localProductSelected');
    setProductSelected({});

    getProducts();
  }, []);

  // API 
  const getProducts = () => {
    ShadeService.getAll().then(response => {

      if (response.status === 200) {
        setShades(response.data)
      }
    }).catch(e => {
      console.log(e);
    });
  }

  return (
    <div className="" id="">
      <div className='container-title-header'>
        <h2 className="shade-title mb-0 text-center">Shades</h2>
      </div>

      {
        shades.map((product, index) => (
          <div className={`py-3 ${(index % 2 === 0) ? `shade-container` : ``}`}>
            <div className="container">
              <div className={`row row-cols-1 row-cols-lg-2 ${(index % 2 === 0) ? `shade-container` : ``}`} key={product.id} >
                <div className="feature col "  >
                  <p>
                    <img className='image-shade' src={product.imageURL} alt="" />
                  </p>
                </div>
                <div className="feature col g-4 px-5">
                  <h2 className='shade-product-title text-center pb-4'>{product.name}</h2>
                  <h6 className="shade-product-subtitle pb-2 border-bottom">{product.subtitle}</h6>
                  <p>{product.description}</p>
                  <div dangerouslySetInnerHTML={{ __html: product.description }} />
                  <Link to={`/items/${product.id}`} className='btn btn-rounded mt-3'>
                    select {`>>`}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Shades