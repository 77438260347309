import React, { useEffect, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import './App.css';
import PersistLogin from './components/layouts/PersistLogin';
import RequireAuth from './components/layouts/RequireAuth';
import Navbar from './components/layouts/Navbar'
import Header from './components/layouts/Header'
import Footer from './components/layouts/Footer'
import Shades from './components/shades/Shades';
import Items from './components/items/Items';
import Cart from './components/cart/Cart';
import AccountSignIn from './components/user-pages/AccountSignIn';
import ForgotPassword from './components/user-pages/ForgotPasswordForm';
import RecoveryPassword from './components/user-pages/RecoveryPasswordForm';
import Voucher from './components/cart/Voucher';
import Unauthorized from './components/layouts/Unauthorized';
import Pending from './components/layouts/Pending';

const ROLES = {
  'wholesaler': "wholesaler"
}

function App() {


  useEffect(() => {

  }, []);


  return (

    <main>
      <div className='d-flex flex-column min-vh-100'>
        <Navbar />
        <Header allowedRoles={[ROLES.wholesaler]} />
        <Routes>
          <Route path={'/signin'} element={<AccountSignIn />} />
          <Route path={'/forgot-password'} element={<ForgotPassword />} />
          <Route path={'/recovery-password/:token'} element={<RecoveryPassword />} />
          <Route path='/unauthorized' element={<Unauthorized />} />
          <Route path='/pending' element={<Pending />} />
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[ROLES.wholesaler]} />}>
              <Route path={'/'} element={<Navigate to={'/products'} />} />
              <Route path={'/products'} element={<Shades />} />
              <Route path={'/items/:product_id'} element={<Items />} />
              <Route path={'/items/:product_id/edit/:uid'} element={<Items />} />
              <Route path={'/cart'} element={<Cart />} />
              <Route path={'/voucher'} element={<Voucher />} />
            </Route>
          </Route>

        </Routes>
        {/* <Routes>
          <Route path={'/'} element={<Navigate to={'/shades'} />} />
          <Route path={'/shades'} element={<Shades />} />
          <Route path={'/items/:product_id'} element={<Items />} />
          <Route path={'/items/:product_id/edit/:uid'} element={<Items />} />
          <Route path={'/cart'} element={<Cart />} />
          <Route path={'/signin'} element={<AccountSignIn />} />
          <Route path={'/forgot-password'} element={<ForgotPassword />} />
          <Route path={'/recovery-password/:token'} element={<RecoveryPassword />} />
          <Route path={'/voucher'} element={<Voucher />} />
        </Routes> */}
        <Footer />
      </div>
    </main>
  );
}

export default App;
