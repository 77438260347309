import React, { useState, useEffect, useContext } from 'react';
import DataContext from '../../context/DataContext';

const Image = () => {

    const [dataFetched, setDataFetched] = useState(false);
    const [product, setProduct] = useState({ items: [] });
    const { productSelected, setProductSelected } = useContext(DataContext);

    useEffect(() => {

        console.log("🚀 ~ file: Image.js ~ line 10 ~ useEffect ~ productSelected", productSelected)
        if (productSelected === null || Object.keys(productSelected).length === 0) {
            const localProductSelected = JSON.parse(localStorage.getItem('localProductSelected'));
            console.log("🚀 ~ file: Image.js ~ line 14 ~ useEffect ~ localProductSelected", localProductSelected)

            if (localProductSelected !== null) {
                setDataFetched(true);
            }
            setProduct(localProductSelected);
        } else {
            console.log("🚀 ~ file: Image.js ~ line 20 ~ useEffect ~ productSelected", productSelected)
            setProduct(productSelected);
            setDataFetched(true);
        }

    }, [productSelected]);

    return (
        <>
            {/* <img src={product.baseImageURL ?? ""} alt="" /> */}
            {(dataFetched)
                ?
                <>
                    <img src={product.product_base_image ?? ""} alt="" />
                    {
                        product.items.map(step => (
                            step.items.map(item => (
                                (item.change_image === 1) ? <img src={item.image} alt="" /> : null
                            ))
                        ))
                    }
                </>
                :
                null
            }

        </>

    )
}

export default Image